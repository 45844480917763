<template>
  <div class="not-found">
    <img
      alt="PWC logo"
      :src="pwcLogo"
      class="logo"
    >
    <div class="content">
      <p class="heading">
        404
      </p>
      <p class="text">
        {{ $t('notFound.text') }}
      </p>
      <custom-button
        class="homepage-button"
        @click="$router.push({ name: 'Dashboard' })"
      >
        {{ $t('notFound.button') }}
      </custom-button>
    </div>
  </div>
</template>

<script>
import store from '../store'
import pwcLogo from '@/assets/pwc_outline_logo.svg'
import CustomButton from '@/components/GlobalComponents/CustomButton.vue'

export default {
  name: 'ErrorPage',
  components: {
    CustomButton
  },
  data () {
    return {
      pwcLogo
    }
  },
  beforeRouteEnter (to, from, next) {
    store.commit('Layout/SET_LAYOUT', 'simple')
    next()
  },
  beforeRouteLeave (to, from, next) {
    store.commit('Layout/SET_LAYOUT', 'complex')
    next()
  }
}
</script>

<style lang="scss" scoped>
div.not-found {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 53px 60px;
  background-color: $primary-black;

  img.logo {
    width: 110px;
    height: 83px;
  }

  div.content {
    padding: 149px 0 0 156px;

    p.heading {
      color: $primary-lightorange;
      font-weight: 700;
      font-size: 203.218px;
      line-height: 248px;
    }

    p.text {
      color: $primary-white;
      font-size: 24.43px;
      line-height: 29px;
      margin: 0;
    }

    .homepage-button {
      margin-top: 124px;
    }
  }
}

@media screen and (max-width: $mobile) {
  div.not-found {

    img.logo {
      width: 53.13px;
      height: 40px;

    }
    div.content {
      padding: 0;
      margin-top: 46px;

      p.heading {
        font-size: 130.08px;
        line-height: 158.83px;
      }

      p.text {
        font-size: 20px;
        line-height: 32px;
      }
    }
  }
}
</style>
